import React from "react"
import { graphql } from "gatsby"
import Layout from '../layout/layout'

const Template = ({ data }) => {

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, timeToRead } = markdownRemark

  return (
    <Layout pageTitle={frontmatter.title} pageHeading={frontmatter.title}>

      <div>
        <div>
          <p><small>Published on {frontmatter.date}. {timeToRead} minutes to read.</small></p>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>

    </Layout>
    
  )
}

export default Template

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        slug
        title
      }
    }
  }
`